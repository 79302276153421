<template>
  <headerview></headerview>
  <router-view :key="$route.fullPath" />
  <footercomp></footercomp>
</template>

<script>
import headerview from "./components/HeaderView.vue";
import footercomp from "./components/FooterComp.vue";

import axios from "axios";

export default {
  name: "App",
  components: {
    // sidebar,
    headerview,
    footercomp,
  },
  data() {
    return {
      total: 0,
      label: [],
    };
  },
  async mounted() {
    await this.getData();
  },
  methods: {
    hitungJumlah(num) {
      this.total = this.total + num;
    },
    getData() {
      var setLang = 1;
      if (typeof this.$route.params.lang === "undefined") {
        setLang = 1;
      } else {
        if (this.$route.params.lang == "en") {
          setLang = 1;
        } else if (this.$route.params.lang == "id") {
          setLang = 2;
        } else {
          setLang = 1;
        }
      }

      //get label
      axios({
        method: "POST",
        url: this.$store.state.apihost + "labels/" + setLang,
        data: "",
        headers: {
          Authorization: "Bearer E5S683EDpJymaEPfdRsCQdtXmnPcmECR",
        },
      }).then((response) => {
        this.$store.commit("savelabel", response.data);
      });

      //get brands
      axios({
        method: "POST",
        url: this.$store.state.apihost + "brandbar",
        data: "",
        headers: {
          Authorization: "Bearer E5S683EDpJymaEPfdRsCQdtXmnPcmECR",
        },
      }).then((response) => {
        this.$store.commit("savebrand", response.data);
      });

      //get cities
      axios({
        method: "POST",
        url: this.$store.state.apihost + "cities",
        data: "",
        headers: {
          Authorization: "Bearer E5S683EDpJymaEPfdRsCQdtXmnPcmECR",
        },
      }).then((response) => {
        this.$store.commit("savecity", response.data);
      });

      //get slideshow
      axios({
        method: "POST",
        url: this.$store.state.apihost + "slideshows",
        data: "",
        headers: {
          Authorization: "Bearer E5S683EDpJymaEPfdRsCQdtXmnPcmECR",
        },
      }).then((response) => {
        this.$store.commit("saveslideshows", response.data);
      });

      //get Socmeds
      axios({
        method: "POST",
        url: this.$store.state.apihost + "socmeds",
        data: "",
        headers: {
          Authorization: "Bearer E5S683EDpJymaEPfdRsCQdtXmnPcmECR",
        },
      }).then((response) => {
        this.$store.commit("savesocmeds", response.data);
      });

      //get Language
      axios({
        method: "POST",
        url: this.$store.state.apihost + "languages",
        data: "",
        headers: {
          Authorization: "Bearer E5S683EDpJymaEPfdRsCQdtXmnPcmECR",
        },
      }).then((response) => {
        this.$store.commit("savelanguages", response.data);
      });
    },
  },
};
</script>
<style>
html {
  scroll-behavior: smooth;
}
@font-face {
  font-family: "Montserrat-swap";
  font-display: swap;
  font-style: normal;
  font-weight: normal;
  src: local("Montserrat"), local("Montserrat-Regular"),
    url(https://fonts.googleapis.com/css2?family=Montserrat:wght@200;400&display=swap)
      format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

body {
  font-family: "Montserrat-swap", sans-serif !important;
  background: #fff;
  line-height: 23px;
  color: #414141;
  letter-spacing: 0.3px;
  overflow-x: hidden;
}

.image-responsive {
  width: 100%;
  height: auto;
}
</style>
