<template>
  <div class="container">
    <div class="row">
      <div class="col-12 title-slider-city text-start">
        {{ $store.getters.labels.CITY_HEAD_SECTION_TITLE }}
      </div>
      <div class="col-12">
        <swiper
          :modules="modules"
          :slides-per-view="4"
          :space-between="20"
          navigation
          class="d-md-block d-none"
        >
          <swiper-slide
            class="swiper-city"
            v-for="data in $store.getters.cities"
            :key="data.city_id"
          >
            <a
              target="_blank"
              rel="noopener"
              :href="data.city_url_redirect"
              v-if="data.city_url_redirect"
            >
              <img
                :src="data.city_image + '?d=500x408&location=center'"
                class="image-responsive"
                alt=""
              />
              <div class="slider-caption text-start">
                <div class="title-city-slider">{{ data.city_name }}</div>
                <!-- <div class="subtitle-city-slider">
                  {{ $store.getters.labels.CITY_NAME_SUBTEXT }}
                </div> -->
              </div>
            </a>
            <router-link
              class="text-no-decoration"
              :to="
                '/' +
                $store.getters.selectlanguage +
                '/city/' +
                data.city_name.replace(/\s+/g, '-').toLowerCase()
              "
              v-else
            >
              <img
                :src="data.city_image + '?d=500x408&location=center'"
                class="image-responsive"
                alt=""
              />
              <div class="slider-caption text-start">
                <div class="title-city-slider">{{ data.city_name }}</div>
                <!-- <div class="subtitle-city-slider">
                  {{ $store.getters.labels.CITY_NAME_SUBTEXT }}
                </div> -->
              </div>
            </router-link>
          </swiper-slide>
        </swiper>
        <swiper
          :modules="modules"
          :slides-per-view="1.2"
          :space-between="20"
          class="d-md-none"
        >
          <swiper-slide
            class="swiper-city"
            v-for="data in $store.getters.cities"
            :key="data.city_id"
          >
            <a
              target="_blank"
              rel="noopener"
              :href="data.city_url_redirect"
              v-if="data.city_url_redirect"
            >
              <img
                :src="data.city_image + '?d=500x408&location=center'"
                class="image-responsive"
                alt=""
              />
              <div class="slider-caption text-start">
                <div class="title-city-slider">{{ data.city_name }}</div>
                <!-- <div class="subtitle-city-slider">
                  {{ $store.getters.labels.CITY_NAME_SUBTEXT }}
                </div> -->
              </div>
            </a>
            <router-link
              class="text-no-decoration"
              :to="
                '/' +
                $store.getters.selectlanguage +
                '/city/' +
                data.city_name.replace(/\s+/g, '-').toLowerCase()
              "
              v-else
            >
              <img
                :src="data.city_image + '?d=500x408&location=center'"
                class="image-responsive"
                alt=""
              />
              <div class="slider-caption text-start">
                <div class="title-city-slider">{{ data.city_name }}</div>
                <!-- <div class="subtitle-city-slider">
                  {{ $store.getters.labels.CITY_NAME_SUBTEXT }}
                </div> -->
              </div>
            </router-link>
          </swiper-slide>
          <div class="swiper-pagination swiper-pagination-white"></div>
        </swiper>
      </div>
    </div>
  </div>
</template>
<script>
// import Swiper core and required modules
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

// Import Swiper styles
export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    return {
      modules: [Navigation, Pagination, Scrollbar, A11y],
    };
  },
};
</script>

<style>
.swiper-button-prev,
.swiper-button-next {
  background: white;
  padding: 23px;
  border-radius: 31px;
}
.swiper-button-next::after {
  font-size: 28px;
  padding-left: 5px;
  color:#20b815;
  font-weight: bold;
}
.swiper-button-prev::after {
  font-size: 28px;
  padding-right: 5px;
  color:#20b815;
  font-weight: bold;
}
.swiper-city {
  position: relative;
}
.slider-caption {
  background-image: linear-gradient(
    to bottom,
    rgba(255, 0, 0, 0),
    rgb(0 0 0)
  ) !important;
  padding-top: 30px;
  position: absolute;
  bottom: 0;
  width: 100%;
  padding-left: 15px;
  padding-bottom: 15px;
}
.title-slider-city {
  font-size: 33px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #353536;
  padding-top: 20px;
  padding-bottom: 20px;
}

.title-city-slider {
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: -0.12px;
  text-align: left;
  color: #fff;
}
.subtitle-city-slider {
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.78;
  letter-spacing: -0.09px;
  color: #fff;
}
@media (max-width: 768px) {
  .title-slider-city {
    font-size: 25px;
  }
}
</style>