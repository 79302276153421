export default {
    regions: state => {
        return state.regions.sort((a, b) => {
            return a['city_id'] < b['city_id'];
        });
    },
    slideshows: state => {
        return state.slideshows;
    },
    brands: state => {
        return state.brands;
    },
    cities: state => {
        return state.cities;
    },
    socmeds: state => {
        return state.socmeds;
    },
    hotels: state => {
        return state.hotels;
    },
    labels: state => {
        return state.labels;
    },
    languages: state => {
        return state.languages;
    },
    selectlanguage: state => {
        return state.selectlanguage;
    },
}