import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";

import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faPhone, faChevronDown, faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { faFacebookF, faTwitter, faInstagram } from "@fortawesome/free-brands-svg-icons";
library.add(faPhone, faChevronDown, faMagnifyingGlass, faFacebookF, faTwitter, faInstagram);

createApp(App).use(store).use(router).component("font-awesome-icon", FontAwesomeIcon).mount("#app");