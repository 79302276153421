<template>
    <div class="container mt-72">
        <div class="search-area">
            <div class="row text-asd h-100">
                <div
                        class="
            col-12
            text-search-box text-center
            align-self-center
            text-white
          "
                >
                    {{ $store.getters.labels.HEADER_BANNER_TEXT }}
                    <br/>
                </div>
                <div class="col-12 text-search-box text-center align-self-center">
                    <div class="box-search-container">
                        <input
                                v-model="search"
                                class="search-text"
                                :class="{ active: isActive }"
                                type="text"
                                @click="activate()"
                                :placeholder="$store.getters.labels.CHOOSE_DESTINATION"
                        />

                        <div class="card list-region-box" :class="{ active: isActive }" @click="activate()">
                            <div class="card-body">
                                <ul class="text-start">
                                    <template v-for="data in filteredData" :key="data.city_name">
                                        <a
                                                class="text-no-decoration"
                                                target="_blank"
                                                rel="noopener"
                                                :href="data.city_url_redirect"
                                                v-if="data.city_url_redirect"
                                        >
                                            <li class="list-city-search">
                                                {{ data.city_name }}, {{ data.country_name }}
                                            </li>
                                        </a>
                                        <router-link
                                                class="text-no-decoration"
                                                :to="
                        '/' +
                        $store.getters.selectlanguage +
                        '/city/' +
                        data.city_name.replace(/\s+/g, '-').toLowerCase()
                      "
                                                v-else
                                        >
                                            <li class="list-city-search">
                                                {{ data.city_name }}, {{ data.country_name }}
                                            </li>
                                        </router-link>
                                    </template>
                                </ul>
                            </div>
                        </div>
                        <button class="btn-search-mobile">
                            <font-awesome-icon icon="magnifying-glass"/> &nbsp;
                        </button>
                    </div>
                    <button class="btn-search">
                        <font-awesome-icon icon="magnifying-glass"/> &nbsp;
                        {{ $store.getters.labels.SEARCH_HOTELS }}
                    </button>
                </div>
            </div>
        </div>
        <div class="row slider-container">
            <div class="col-12">
                <swiper
                        :modules="modules"
                        :slides-per-view="1"
                        :space-between="50"
                        :pagination="{ clickable: true }"
                        :scrollbar="{ draggable: true }"
                        :autoplay="{
            delay: 5000,
            disableOnInteraction: false
          }"
                        @swiper="onSwiper"
                        @slideChange="onSlideChange"
                >
                    <swiper-slide
                            v-for="data in $store.getters.slideshows"
                            :key="data.slideshow_id"
                    >
                        <img
                                :src="data.image_desktop + '?d=1136x352&location=center'"
                                class="image-responsive d-md-block d-none"
                                alt="PBA Slider"
                        />
                        <img
                                :src="data.image_mobile + '?d=536x352&location=center'"
                                class="image-responsive d-md-none"
                                alt="PBA Slider"
                        />
                    </swiper-slide>
                    <div class="swiper-pagination swiper-pagination-white"></div>
                </swiper>
            </div>
        </div>
    </div>
    <slidercity></slidercity>
</template>
<script>
    import {Pagination, A11y, Autoplay} from "swiper";
    import {Swiper, SwiperSlide} from "swiper/vue";
    import "swiper/css";
    import "swiper/css/pagination";
    import "swiper/css/scrollbar";
    import slidercity from "@/views/SliderCity.vue";

    export default {
        components: {
            Swiper,
            SwiperSlide,
            slidercity,
        },
        data() {
            return {
                modifiedRegionData: [],
                search: "",
                selectedCity: "",
                isActive: false,
            };
        },
        setup() {
            return {
                modules: [Pagination, A11y, Autoplay],
            };
        },
        computed: {
            filteredData() {
                return this.$store.state.cities.filter(
                    (data) =>
                        data.city_name.toLowerCase().includes(this.search.toLowerCase()) ||
                        data.country_name.toLowerCase().includes(this.search.toLowerCase())
                );
            },
        },
        methods: {
            activate() {
                this.isActive = !this.isActive;
            },
        },
    };
</script>

<style>
    .mt-72 {
        margin-top: 72px;
    }

    .list-city-search:hover {
        color: #fff;
        background-color: #4b4b4d;
    }

    .text-no-decoration:hover {
        color: inherit;
    }

    .text-no-decoration {
        text-decoration: none;
        color: #414141;
    }

    ::-webkit-scrollbar {
        width: 10px;
    }

    ::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    ::-webkit-scrollbar-thumb {
        background: #888;
    }

    ::-webkit-scrollbar-thumb:hover {
        background: #555;
    }

    .swiper-pagination-bullet-active {
        background-color: #fff;
    }

    .swiper-pagination {
        text-align: left;
        margin-left: 15px;
    }

    .slider-container {
        margin-top: 15px !important;
    }

    .list-city-search {
        padding-top: 5px;
        padding-bottom: 5px;
        padding-left: 32px;
    }

    .list-region-box > .card-body > ul {
        list-style: none;
        line-height: 23px;
        color: #414141;
        letter-spacing: 0.3px;
        font-size: 15px;
        font-weight: normal;
        cursor: pointer;
        margin-bottom: unset;
        padding-left: 0px;
    }

    .list-region-box {
        position: absolute !important;
        width: inherit !important;
        z-index: 2;
        max-height: 200px;
        overflow-y: auto;
        display: none !important;
    }

    .list-region-box.active {
        display: block !important;
        border-radius: 0;
        transition: 2s;
    }

    .box-search-container {
        width: 560px;
        display: inline-block;
        position: relative;
    }

    .popular-city {
        background-color: #fff;
        width: 560px;
    }

    .btn-search {
        width: 208px;
        height: 56px;
        border-radius: 28px;
        border: solid 1px #20b815;
        background-color: #20b815;
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: -0.08px;
        color: #fff;
        margin-left: 15px;
    }

    .search-text {
        width: 100%;
        border: solid 1px #4b4b4d;
        border-radius: 30px;
        height: 56px;
        margin-top: 24px;
        font-size: 16px;
        color: #4b4b4d;
        padding-left: 45px;
    }

    .search-text.active {
        border-radius: 0 !important;
        transition: 0s;
    }

    .search-area {
        min-height: 176px;
        background-color: #4b4b4d;
        padding-top: 32px;
        padding-bottom: 32px;
        padding-left: 15px;
        padding-right: 15px;
    }

    .text-search-box {
        font-size: 26px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.23;
        letter-spacing: -0.13px;
    }

    .btn-search-mobile {
        display: none;
    }

    @media (max-width: 768px) {
        .box-search-container {
            width: 100%;
            display: inline-block;
        }

        .list-region-box {
            width: 100%;
        }

        .btn-search {
            display: none;
        }

        .search-text {
            text-align: left;
            padding-left: 20px;
        }

        .box-search-container {
            position: relative;
        }

        .btn-search-mobile {
            width: 45px;
            height: 45px;
            border-radius: 28px;
            border: solid 1px #20b815;
            background-color: #20b815;
            font-size: 16px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            color: #fff;
            padding-left: 12px;
            position: absolute;
            right: 6px;
            top: 30px;
            padding-top: 3px;
            display: block;
        }

        .btn-search-mobile .svg-inline--fa {
            margin: 10px 0;
            vertical-align: unset;
        }

    }
</style>