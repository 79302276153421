export default {
    savehotels(state, val) {
        state.hotels = val
    },
    savelabel(state, val) {
        state.labels = val
    },
    savebrand(state, val) {
        state.brands = val
    },
    savecity(state, val) {
        state.cities = val
    },
    saveconfig(state, val) {
        state.configs = val
    },
    saveslideshows(state, val) {
        state.slideshows = val
    },
    savesocmeds(state, val) {
        state.socmeds = val
    },
    savelanguages(state, val) {
        state.languages = val
    },
    changeLanguage(state, val) {
        state.selectlanguage = val
    },
    switchRender(state, val) {
        state.renderpage = val
    },
}