export default {
    // apihost: "http://www.archipelagointernational.local/api/pba/site/",
    apihost: "https://www.archipelagointernational.com/api/pba/site/",
    labels: [],
    brands: [],
    cities: [],
    selectlanguage: "en",
    slideshows: [],
    socmeds: [],
    language: [],
    hotels: []
}