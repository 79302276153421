<template>
  <div class="top-header fixed-top">
    <div class="container">
      <div class="row">
        <div class="col float-start">
          <router-link to="/">
            <img
              class="image-responsive-height"
              src="https://static.archipelagointernational.com/v1/imgs/Homepage/PbA_FC_2-Line.svg"
            />
          </router-link>
        </div>
        <div class="col float-end text-end align-self-center">
          <div class="search-bar d-inline-block">
            <div
              class="box-search-container-bar"
              v-if="$route.name !== 'Index' && $route.name !== 'Homepage'"
            >
              <div class="input-group input-group-bar">
                <input
                  v-model="search_bar"
                  class="search-text-bar"
                  :class="{ active: isActive }"
                  type="text"
                  @click="activate()"
                  placeholder="Choose your destination"
                />
                <button class="btn-search-bar">
                  <font-awesome-icon icon="magnifying-glass" /> &nbsp;
                  {{ $store.getters.labels.SEARCH_HOTELS }}
                </button>
              </div>
              <div
                class="card list-region-box-bar"
                :class="{ active: isActive }"
                @click="activate()"
              >
                <div class="card-body">
                  <ul class="text-start">
                    <template
                      v-for="data in filteredData"
                      :key="data.city_name"
                    >
                      <a
                        class="text-no-decoration"
                        target="_blank"
                        rel="noopener"
                        :href="data.city_url_redirect"
                        v-if="data.city_url_redirect"
                      >
                        <li class="list-city-search-bar">
                          {{ data.city_name }}, {{ data.country_name }}
                        </li>
                      </a>
                      <router-link
                        class="text-no-decoration"
                        :to="
                          '/' +
                          $store.getters.selectlanguage +
                          '/city/' +
                          data.city_name.replace(/\s+/g, '-').toLowerCase()
                        "
                        v-else
                      >
                        <li class="list-city-search-bar">
                          {{ data.city_name }}, {{ data.country_name }}
                        </li>
                      </router-link>
                    </template>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <select
            class="switch-language d-none"
            @change="switchLanguage($event)"
          >
            <option
              :value="data.lang_uri"
              v-for="data in $store.getters.languages"
              :key="data.lang_name"
            >
              {{ data.lang_name }}
            </option>
          </select>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      search_bar: "",
      selectedCity: "",
      isActive: false,
    };
  },
  computed: {
    isNotHome() {
      return this.$route.name !== "Index" && this.$route.name !== "Home";
    },
    filteredData() {
      return this.$store.state.cities.filter(
        (data) =>
          data.city_name
            .toLowerCase()
            .includes(this.search_bar.toLowerCase()) ||
          data.country_name
            .toLowerCase()
            .includes(this.search_bar.toLowerCase())
      );
    },
  },
  methods: {
    activate() {
      this.isActive = !this.isActive;
    },
    async switchLanguage(event) {
      var lang = event.target.value;
      var setLang = 1;
      if (lang == "en") {
        setLang = 1;
      } else {
        setLang = 2;
      }
      await axios({
        method: "POST",
        url: this.$store.state.apihost + "labels/" + setLang,
        data: "",
        headers: {
          Authorization: "Bearer E5S683EDpJymaEPfdRsCQdtXmnPcmECR",
        },
      }).then((response) => {
        this.$store.commit("savelabel", response.data);
      });

      this.$store.commit("changeLanguage", lang);
      var getpath = this.$router.currentRoute.fullPath;
      console.log(this.$router.name);
      if (typeof this.$router.params === "undefined") {
        this.$router.push({ path: "/" + lang });
      } else {
        if (lang != this.$route.params.lang) {
          var newpath = getpath.slice(3);
          this.$router.push({ path: newpath });
        }
      }
    },
  },
};
</script>

<style>
.switch-language {
  border: unset;
  font-weight: bold;
}
.list-region-box-bar.active {
  display: block !important;
  border-radius: 0;
  transition: 2s;
}
.search-text-bar.active {
  border-radius: 0 !important;
  transition: 0.5s;
}
.input-group-bar {
  margin-right: 25px;
}
.btn-search-bar {
  width: 150px;
  height: 40px;
  border-radius: 28px !important;
  border: solid 1px #20b815;
  background-color: #20b815;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.08px;
  color: #fff;
  margin-left: -20px !important;
}
.list-region-box-bar > .card-body > ul {
  list-style: none;
  line-height: 23px;
  color: #414141;
  letter-spacing: 0.3px;
  font-size: 15px;
  font-weight: normal;
  cursor: pointer;
  margin-bottom: unset;
  padding-left: 0px;
}
.list-region-box-bar {
  position: absolute !important;
  width: 350px !important;
  z-index: 2;
  max-height: 200px;
  overflow-y: auto;
  display: none !important;
}
.list-region-box-bar.active {
  display: block !important;
  border-radius: 0;
  transition: 2s;
}
.box-search-container-bar {
  width: 500px;
  display: inline-block;
  position: relative;
}
.search-text-bar {
  width: 350px;
  height: 40px;
  padding: 0 0 0 24px;
  border-radius: 20px;
  border: solid 1px #c9c9c9;
  background-color: #fff;
  font-size: 14px;
}
.search-text-bar::placeholder {
  color: #8f8f8f;
}
.top-header {
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.25);
  background-color: #fff;
  height: 72px;
  padding-top: 15px;
  padding-bottom: 15px;
}
.image-responsive-height {
  height: 42px;
  width: auto;
}
.language-text {
  font-size: 14px;
  cursor: pointer;
}
.list-city-search-bar {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
}
.list-city-search-bar:hover {
  color: #fff;
  background-color: #4b4b4d;
}
@media (max-width: 768px) {
  .box-search-container-bar {
    display: none !important;
  }
}
</style>