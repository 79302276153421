<template>
  <div class="vld-parent">
    <loading v-model:active="isLoading" />
  </div>
  <div class="container mt-110">
    <div class="row" style="margin-top: 3em">
      <div class="col-12">
        <div class="city-title">
          {{ city_name }}
        </div>
        <div class="city-description d-none">
          {{ $store.getters.labels.POWERED_CITY_PAGE }}
        </div>
      </div>
      <div
        class="col-md-6 col-12"
        v-for="data in $store.getters.hotels"
        :key="data.hotel_id"
      >
        <div class="box-hotel">
          <img
            class="image-responsive"
            :src="data.thumbnail_desktop + '?d=636x300'"
          />
          <div class="box-description-hotel">
            <div class="hotel-name">{{ data.hotel_name }}</div>
            <div class="hotel-city-region text-start">
              {{ data.city_name }}, {{ data.country_name }}
            </div>
            <div class="hotel-starting-from text-start">
              {{ $store.getters.labels.STARTING_FROM }}
            </div>
            <div class="hotel-price">
              <span class="discounted-price">{{
                data.hotel_currency + " " + formatNumber(data.price.rate)
              }}</span>
              <span v-if="data.price.before_discount" class="from-price">{{
                data.hotel_currency +
                " " +
                formatNumber(data.price.before_discount)
              }}</span>
              <span
                v-if="data.price.discount_percentage"
                class="discounted-percentage"
                >{{ data.price.discount_percentage + "%" }}</span
              >
            </div>
            <div class="button-container-box">
              <a
                v-if="data.hotel_website"
                :href="data.hotel_website"
                target="_blank"
                class="text-no-decoration visit-web-btn"
                rel="noopener"
                >{{ $store.getters.labels.VISIT_WEBSITE }}</a
              >
              <a
                :href="data.hotel_simplebooking"
                target="_blank"
                class="text-no-decoration book-now-btn"
                rel="noopener"
                >{{ $store.getters.labels.BOOK_NOW }}</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import axios from "axios";
export default {
  data() {
    return {
      isLoading: false,
      fullPage: true,
      city_name: "",
    };
  },
  components: {
    Loading,
  },
  async mounted() {
    await this.getDataHotel();
  },
  methods: {
    capitalizeFirstLetter: (str) => {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
    formatNumber(number) {
      return Intl.NumberFormat().format(number);
    },
    getDataHotel() {
      //get hotel
      this.isLoading = true;
      var sendvar = {
        city_name: this.$route.params.name,
      };
      axios({
        method: "post",
        url:
          this.$store.state.apihost +
          "hotels-by-city/" +
          this.$route.params.name,
        data: sendvar,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Basic E5S683EDpJymaEPfdRsCQdtXmnPcmECR",
        },
      }).then((response) => {
        this.city_name = response.data[0].city_name;
        this.$store.commit("savehotels", response.data);
        this.isLoading = false;
      });
    },
  },
};
</script>
<style>
.mt-110 {
  margin-top: 110px;
}

.button-container-box {
  text-align: right;
}
.city-description {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: -0.08px;
  text-align: left;
  color: #353536;
  margin-top: 15px;
  margin-bottom: 3em;
}
.city-title {
  font-size: 26px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: -0.13px;
  text-align: left;
  color: #353536;
  margin-bottom: 1em;
}
.book-now-btn:hover {
  background-color: #23c717;
  color: #fff;
}
.book-now-btn {
  padding: 11px 34px;
  border-radius: 20px;
  border: solid 1px #20b815;
  background-color: #20b815;
  color: #fff;
}
.visit-web-btn {
  border-radius: 20px;
  border: solid 1px #aeaeae;
  background-color: #fff;
  padding: 11px 26px;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.07px;
  text-align: center;
  color: #aeaeae;
  margin-right: 15px;
}
.hotel-price {
  margin-top: 10px;
}
.discounted-percentage {
  font-size: 11px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.91;
  letter-spacing: -0.06px;
  text-align: left;
  color: #20b815;
}
.from-price {
  text-decoration-line: line-through;
  text-decoration-color: #707070;
  font-size: 11px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.91;
  letter-spacing: -0.06px;
  text-align: left;
  color: #aeaeae;
  margin-right: 6px;
}
.discounted-price {
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: -0.1px;
  text-align: left;
  color: #353536;
  margin-right: 15px;
}
.hotel-starting-from {
  font-size: 11px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.06px;
  text-align: center;
  color: #353536;
  margin-top: 25px;
}
.hotel-city-region {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.08px;
  text-align: center;
  color: #353536;
  margin-top: 5px;
}
.hotel-name {
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: -0.1px;
  text-align: left;
  color: #353536;
}
.box-description-hotel {
  padding: 15px 15px 30px 15px;
  border: solid 1px #efefef;
  margin-bottom: 3em;
}
@media (max-width: 768px) {
  .button-container-box {
    margin-top: 2em;
    text-align: center;
  }
}
</style>