<template>
  <footer class="footer white-footer">
    <div class="container">
      <div class="row">
        <div class="col-12 d-md-block d-none align-self-center">
          <router-link to="/">
            <img
              class="image-responsive-height logo-footer"
              src="https://cdn0.archipelagointernational.com/v1/imgs/Homepage/PbA_FC_2-Line.svg"
            />
          </router-link>
          <div class="text-footer">
            {{ $store.getters.labels.POWER_YOUR }}
            <span class="slide-up">{{ word }}.</span>
          </div>
        </div>
        <div class="col-12 d-md-none align-self-center">
          <router-link to="/">
            <img
              class="image-responsive-height logo-footer"
              src="https://cdn0.archipelagointernational.com/v1/imgs/Homepage/PbA_FC_2-Line.svg"
            />
          </router-link>
        </div>
        <div class="col-12 d-md-none align-self-center">
          <div class="text-footer">
            {{ $store.getters.labels.POWER_YOUR }} <span>{{ word }}.</span>
          </div>
        </div>
      </div>
    </div>
  </footer>
  <footer class="footer grey-footer">
    <div class="container">
      <div class="row">
        <a
          :href="data.brand_link"
          class="col d-none d-md-block align-self-center text-center"
          v-for="data in $store.getters.brands"
          :key="data.brand_name"
          target="_blank"
        >
          <img class="brand-logo-footer" :src="data.brand_logo_white" />
        </a>
        <a
          :href="data.brand_link"
          class="col-6 d-md-none align-self-center text-center logo-mobile"
          v-for="data in $store.getters.brands"
          :key="data.brand_name"
          target="_blank"
        >
          <img class="brand-logo-footer" :src="data.brand_logo_white" />
        </a>
      </div>
    </div>
  </footer>
  <footer class="footer grey-footer footer-socmed">
    <div class="container h-100">
      <div class="row h-100">
        <a
          class="
            col-md-6 col-sm-12
            align-self-center
            text-no-decoration text-white
            order-md-1 order-2
          "
          target="_blank"
          href="https://www.archipelagointernational.com"
        >
          Copyright {{ new Date().getFullYear() }} © Archipelago International
        </a>
        <div
          class="
            col-md-6 col-sm-12
            order-md-2 order-1
            footer-icon-area
            align-self-center
          "
        >
          <a
            :href="data.socmed_link"
            target="_blank"
            class="text-no-decoration socmed-icon-footer"
            rel="noopener"
            v-for="data in $store.getters.socmeds"
            :key="data.socmed_name"
            :style="data.custom_style"
          >
            <font-awesome-icon
              class="text-white"
              :icon="{ prefix: 'fab', iconName: data.socmed_icon }"
            />
          </a>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
const words = ["travel", "stay", "journey"];

export default {
  data: function () {
    return {
      word: "",
    };
  },

  methods: {
    updateWord: function () {
      this.word = words[Math.floor(Math.random() * words.length)];
    },
  },

  mounted: function () {
    this.updateWord();
    setInterval(this.updateWord, 2000);
  },
};
</script>

<style>
.socmed-icon-footer {
  font-size: 15px;
  margin-left: 5px;
  margin-right: 5px;
  border: 1px solid #fff;
  border-radius: 100%;
}
.brand-logo-footer {
  width: 85px;
  height: auto;
}
.white-footer {
  box-shadow: 0 -1px 4px 0 rgba(0, 0, 0, 0.25);
  background-color: #fff;
  height: 80px;
  padding-top: 15px;
  padding-bottom: 15px;
  margin-top: 10em;
}
.grey-footer {
  box-shadow: 0 -1px 4px 0 rgba(0, 0, 0, 0.25);
  background-color: #4b4b4d;
  min-height: 72px;
  padding-top: 15px;
  padding-bottom: 15px;
}
.footer-socmed {
  box-shadow: unset;
  font-size: 11px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.22px;
  text-align: left;
  color: #fff;
  background-color: #4b4b4d;
}

.logo-footer {
  border-right: solid 1.5px #aaaaaacc;
  padding-right: 20px;
}

.text-footer > span {
  cursor: pointer;
}
.text-footer {
  display: inline;
  font-size: 30px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #353536;
  margin-left: 20px;
}
.footer-icon-area {
  text-align: right;
}
@media (max-width: 768px) {
  .white-footer {
    height: auto;
    text-align: center;
  }
  .logo-footer {
    border: unset;
    padding-right: 0;
  }
  .text-footer {
    margin-left: 0px;
  }
  .footer-socmed,
  .footer-icon-area {
    text-align: center !important;
  }
  .footer-icon-area {
    margin-top: 1em;
    padding-top: 1em;
    padding-bottom: 2em;
  }
  .logo-mobile {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}
</style>