import { createWebHistory, createRouter } from "vue-router";
import Home from "@/views/HomeView.vue";
import City from "@/views/CityView.vue";

const routes = [{
        path: '/:lang',
        name: "Homepage",
        component: Home
    },
    {
        path: "/",
        name: "Index",
        component: Home
    },
    {
        path: "/:lang/city/:name",
        name: "City Lang",
        component: City
    },
    {
        path: "/city/:name",
        name: "City",
        component: City
    },
    {
        path: "/:lang",
        name: "Home",
        component: Home
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

export default router;